<template>
  <div class="welfare">
    <div class="welfare-title">2022年合作达人福利政策</div>
    <div class="welfare-section">
      <div class="ws-title">一. 推广公众号</div>
    </div>
    <div class="welfare-section">
      <div class="ws-info-title" style="font-weight: bold">1.爆单奖</div>
      <table border="1" class="ws-table ws-table-1">
        <tr>
          <th>有效新增</th>
          <th>奖金</th>
        </tr>
        <tr>
          <td>500人</td>
          <td>20元</td>
        </tr>
        <tr>
          <td>1000人</td>
          <td>50元</td>
        </tr>
        <tr>
          <td>2000人</td>
          <td>100元</td>
        </tr>
        <tr>
          <td>5000人</td>
          <td>300元</td>
        </tr>
        <tr>
          <td>10000人</td>
          <td>800元</td>
        </tr>
      </table>
      <div class="ws-info">
        <div class="ws-info-text">
          a. 本奖励针对达人发布的“
          <span class="ws-text-tips">单个关键词</span>
          ”爆单的情况，在申请奖金时，需附上：<span class="ws-text-tips">对应的推广关键词信息+对应推广书籍文案信息+播放量截图。
          </span>
        </div>
        <div class="ws-info-text">
          b.
          当周爆单奖在次周结算：周三截止统计，周四确定获奖资格，周五支付奖金。节假日顺延。
        </div>
      </div>
    </div>
    <div class="welfare-section">
      <div class="ws-title">2.全勤奖</div>
      <table border="1" class="ws-table">
        <tr>
          <th style="width: 20%">类别</th>
          <th>满足条件</th>
          <th style="width: 20%">累计收入</th>
        </tr>
        <tr>
          <td>周奖</td>
          <td class="nbTd">
            当周发文5天，且，当周总计有效新增>500人，奖励20元。
            <br />
            结算周：上周四至本周三。
          </td>
          <td>20元</td>
        </tr>
        <tr>
          <td>周奖plus</td>
          <td class="nbTd">
            当周发文5天，且，当周总计有效新增>2000人，奖励100元。
            <br />
            结算周：上周四至本周三。
          </td>
          <td>100元</td>
        </tr>
        <tr>
          <td>月奖</td>
          <td class="nbTd">
            当月连续获得4次周奖，再次额外奖励50元。 需连续，中间不能断。
          </td>
          <td>50元</td>
        </tr>
        <tr>
          <td>月奖plus</td>
          <td class="nbTd">
            当月连续获得4次周奖plus，再次额外奖励200元。 需连续，中间不能断。
          </td>
          <td>200元</td>
        </tr>
      </table>
      <div class="ws-info">
        <div class="ws-info-text">
          a. 如果达人获得月奖，则在该月累计获得全勤奖收入为：<span class="ws-text-tips">20元*4+50元=130元。</span>
        </div>
        <div class="ws-info-text">
          b. 如果达人获得月奖plus，则在该月累计获得全勤奖收入为：<span class="ws-text-tips">100元*4+200元=600元。</span>
        </div>
        <div class="ws-info-text">
          c.
          周奖，次周结算：周三截止统计，周四确定获奖资格，周五支付奖金。节假日顺延。
        </div>
        <div class="ws-info-text">
          d.
          月奖，次月结算：10号结束统计并确定获奖资格，10〜15号支付奖金。节假日顺延。
        </div>
      </div>
    </div>
    <div class="welfare-section">
      <div class="ws-title">二. 推广海读小说App</div>
      <div class="ws-info">
        <div class="ws-info-title ws-text-tips">即将开启</div>
      </div>
    </div>
    <div class="welfare-section">
      <div class="ws-title">三. 补充说明</div>
      <div class="ws-info noWeight">
        <div class="ws-info-title">
          1.
          如达人有主观作弊行为（如刷量、假量等），或其他影响平台官方声誉行为（如抹黑、诋毁等），一经发现即扣除达人名下全部应有收益，并拉入合作黑名单。
        </div>
        <div class="ws-info-title">
          2.
          如利用合作达人身份及官方合作者身份，欺骗达人或其他人，谋取非法所得，一经发现即扣除达人名下全部应有收益，并拉入合作黑名单。
        </div>
        <div class="ws-info-title">
          3.
          所有达人应在尊重事实的基础上，积极维护平台官方品牌。无论因任何原因引起之纠纷，均应通过正当途径如双方协商、司法诉讼予以维权和申诉，但不得通过各种方式损害平台官方的形象与名誉。
        </div>
        <div class="ws-info-title">4. 本政策有效期至2022年12月31日。</div>
        <div class="ws-info-title">
          5.
          平台官方对本政策拥有最终解释权，并保留根据实际情况对本政策及具体操作方式进行修订的权利。如遇不可抗力因素，平台官方有权终止本政策。
        </div>
      </div>
    </div>
    <div class="jumpContact" @click="$router.push('/contact')">
      已详细阅读，立即入驻
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  async mounted() { },
  methods: {}
}
</script>

<style scoped lang="less">
.welfare {
  background: white;
  overflow: hidden;
  padding: 0px 30px 20px;
  .welfare-title {
    margin: 50px 0px 30px;
    font-weight: bold;
    font-size: 44px;
  }
  .welfare-section {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: left;
    .ws-title {
      font-weight: bold;
      margin-top: 10px;
    }
    .ws-table {
      margin: 30px 0px;
      width: 100%;
      border-color: #e8e8e8;
      font-size: 24px;
      th {
        background: #f5f4f4;
      }
      th,
      td {
        text-align: center;
        padding: 8px 2px;
      }
    }
    .ws-table-1 {
      th,
      td {
        width: 50%;
      }
    }
    .ws-info {
      margin-top: 20px;
      .ws-info-title {
        font-size: 30px;
        margin-bottom: 12px;
        // font-weight: bold;
      }
      .ws-info-text {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 50px;
      }
    }
    .ws-text-tips {
      color: #ff784c;
    }
  }
  .jumpContact {
    padding: 16px 42px;
    margin-bottom: 80px;
    border-radius: 50px;
    display: inline-block;
    color: #ff8870;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    border: 2px solid #ff8870;
  }
}
.noWeight {
  .ws-info-title {
    font-weight: normal !important;
    margin-bottom: 20px !important;
    line-height: 50px !important;
  }
}
.nbTd {
  padding: 6px !important;
  text-align: left !important;
}
</style>
